
import React, { useEffect, useState } from "react"
import "./style.css"
import Profiles from "./Profiles"
const Scoreboard = () => {
  const [users, setUsers] = useState([])

  const fetchData = async () => {
    const response = await fetch(process.env.REACT_APP_API_KEY)
    const data = await response.json()
    setUsers(data)
    console.log(data)
  }

  useEffect(() => {
    fetchData()
  }, [users])

  return (
  <div className="scoreboard">
    <div className="bg-img"></div>
    <h1 className='Scoreboard'>Scoreboard</h1>
    <div className='board'>
    <div className="header">
      <h1>Rank</h1>
      <h1>Team</h1>
      <h1>Level</h1>
      <h1>Points</h1>
      <h1>College</h1>
    </div>
    <div className="profiles" > {
      users.map((user, index)=>{
        return(
        <div>
          <Profiles user={user} index={index+1} />
          <div className='border'></div>
        </div>
      )})}  
    </div>
    </div>
  </div>  
  
  )
}


export default Scoreboard


